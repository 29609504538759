<template>

   <b-row
    v-if="page"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ page }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item :to="home_url?home_url:'/'">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item :to="url">
                {{folder}}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

 
  </b-row>
<!--     <div>
       <div class="breadcrumb">
            <slot name="header">
               <h1>{{page}}</h1>
                    <ul>
                        <li ><a :href="url" v-if="url"> {{folder}} </a> <a href="" v-else> {{folder}} </a></li>
                        <li> {{page}} </li>
                    </ul>
            </slot>
        </div>
        <div class="separator-breadcrumb border-top"></div>
    </div> -->
</template>
<script>
export default {
    props:['page','folder',"url",'home_url'],
   
}
</script>
